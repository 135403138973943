<template>
  <div class="coupon">
    <div class="head">
      <i class="el-icon-s-ticket"></i>
      <span>优惠券</span>
    </div>

    <div class="content-panel">
      <el-empty style="margin-top: 30px" description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.coupon {
  .head {
    display: flex;
    align-items: center;
    background: #ffffff;
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    padding: 0px 25px;
    margin-bottom: 12px;
    font-weight: bold;
    vertical-align: middle;

    i {
      font-size: 18px;
      margin-right: 4px;
      vertical-align: middle;
    }
  }

  .content-panel {
    background: #ffffff;
    overflow: hidden;
    padding: 25px 25px 25px 25px;
    min-height: 650px;
    position: relative;
  }
}
</style>